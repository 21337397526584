import { css } from '@emotion/react'

const style = {
  tabList: css`
    .nav {
      flex-wrap: nowrap;
      white-space: nowrap;
    }

    .nav-item {
      margin: 0;
      min-width: 160px;
      font-size: inherit;
      line-height: inherit;
    }
  `,

  sliderWrapper: css`
    position: relative;
    margin: 0 -0.5rem;
    padding: 0 0.5rem;

    @media (min-width: 768px) {
      margin: 0 -0.75rem;
      padding: 0 0.75rem;
    }
  `,

  box: css`
    padding: 2px 0.25rem;

    .ck-content h1,
    .ck-content h2,
    .ck-content h3,
    .ck-content h4,
    .ck-content h5,
    .ck-content h6 {
      font-size: var(--fs-xs);
    }

    .ck-content .text-tiny {
      font-size: calc(var(--fs-xss) - 6px);
    }

    .ck-content .text-small {
      font-size: calc(var(--fs-xss) - 2px);
    }

    .ck-content .text-big {
      font-size: var(--fs-sm);
    }

    .ck-content .text-huge {
      font-size: var(--fs-6);
    }

    @media (min-width: 768px) {
      padding: 2px 0.5rem;
    }
  `,
}

export default style
