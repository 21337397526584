import { useRef, useState } from 'react'

const useModal = () => {
  let [modal, setModal] = useState('')

  let history = useRef([])

  let pushModal = nextModal => {
    history.current.push(nextModal)
    setModal(nextModal)
  }

  let replaceModal = nextModal => {
    history.current.splice(-1, 1, nextModal)
    setModal(nextModal)
  }

  let popModal = () => {
    history.current.pop()
    setModal(history.current.slice(-1).pop() || '')
  }

  return { modal, pushModal, replaceModal, popModal }
}

export default useModal
