import { Container } from 'swp-components/lib/components/bootstrap'
import { Heading } from 'swp-components/lib/components/section'

const SectionHeading = ({
  color,
  alignment,
  level,
  variant,
  tag,
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      <Heading
        color={color}
        alignment={alignment}
        level={level}
        variant={variant}
        tag={tag}
      >
        {children}
      </Heading>
    </Container>
  )
}

export default SectionHeading
