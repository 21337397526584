import { I18nContext, localizePath } from 'gatsby-plugin-i18n'
import { useContext, useState } from 'react'
import { Container } from 'swp-components/lib/components/bootstrap'
import {
  Button,
  DataLayer,
  DataLayerScope,
  Tabs,
} from 'swp-components/lib/components/common'
import {
  Card,
  // ConsentModal,
  // ContactModal,
  TermsModal,
} from 'swp-components/lib/components/internet-package'
// import useCaptcha from 'swp-components/lib/hooks/use-captcha'

import useModal from '@/hooks/use-modal'
// import useTolConsent from '@/hooks/use-tol-consent'
// import useTolContact from '@/hooks/use-tol-contact'
import generateSliderOptions from '@/util/generate-slider-options'

import Slider from '../slider'
import style from './style'

const generateSlider = (tab, setSelectedPack, openModal, preferWidth) => {
  let handleAction = pack => {
    return action => {
      if (action === 'show-modal') {
        window.location.assign(
          `${pack.locale === 'en' ? '/en' : ''}/trueonline/package-types/${
            pack.packageOption.tolPackageType.slug
          }/packages/${pack.slug}`
        )
      }
    }
  }

  let sliderOptions = generateSliderOptions(preferWidth)

  let mutationDataToComponent = pack => {
    return {
      ...pack,
      serviceBy: pack.serviceBy
        ? {
            buttonOnCard: pack.serviceType
              ? pack.serviceType.serviceButton.buttonOnCard
              : pack.locale === 'th'
              ? 'ดูรายละเอียด'
              : 'Details',
          }
        : { buttonOnCard: pack.locale === 'th' ? 'ดูรายละเอียด' : 'Details' },
    }
  }
  return (
    <>
      <div css={style.sliderWrapper}>
        <Slider options={sliderOptions}>
          {/* eslint-disable-next-line array-callback-return */}
          {tab.packages.map(pack => {
            if (pack) {
              pack = mutationDataToComponent(pack)
              return (
                <div key={pack.id} css={style.box}>
                  <Card
                    pack={pack}
                    onAction={handleAction(pack)}
                    style={{ height: '100% ' }}
                  />
                </div>
              )
            }
          })}
        </Slider>
      </div>
      <div className="d-flex justify-content-center mt-3">
        {tab.viewAll && (
          <DataLayer
            selector={`InternetPackage.viewAllButton#${tab.title || 'none'}`}
            triggers={['click']}
            context={{ tab }}
            render={ref => (
              <Button
                className="mx-1"
                ref={ref}
                variant="outlined"
                color={tab.viewAll.color}
                link={{ url: tab.viewAll.url, newTab: tab.viewAll.newTab }}
              >
                {tab.viewAll.name}
              </Button>
            )}
          />
        )}
        {tab.selfServiceButton && (
          <DataLayer
            selector={`InternetPackage.salfServiceButton#${
              tab.title || 'none'
            }`}
            triggers={['click']}
            context={{ tab }}
            render={ref => (
              <Button
                className="mx-1"
                ref={ref}
                color={tab.selfServiceButton.color}
                link={{
                  url: tab.selfServiceButton.url,
                  newTab: tab.selfServiceButton.newTab,
                }}
              >
                {tab.selfServiceButton.name}
              </Button>
            )}
          />
        )}
      </div>
    </>
  )
}

const renderContent = (packages, setSelectedPack, openModal, preferWidth) => {
  if (packages.length === 1) {
    return generateSlider(packages[0], setSelectedPack, openModal, preferWidth)
  }

  let tabs = packages.map(tab => ({
    key: tab.title,
    title: tab.title,
    pane: generateSlider(tab, setSelectedPack, openModal, preferWidth),
  }))

  return (
    <Tabs
      tabs={tabs}
      tabItemTag="h3"
      wrapTabList={children => <Slider css={style.tabList}>{children}</Slider>}
    />
  )
}

const InternetPackage = ({
  packages,
  content,
  contactWithSaleContent,
  preferWidth = 280,
  ...props
}) => {
  let config = {
    captcha: {
      siteKey: process.env.GATSBY_INTERNET_PACKAGE_CAPTCHA_SITE_KEY,
      action: process.env.GATSBY_INTERNET_PACKAGE_CAPTCHA_ACTION,
    },
  }

  let [selectedPack, setSelectedPack] = useState(null)

  let { modal, pushModal, replaceModal, popModal } = useModal()

  let openModal = name => {
    switch (name) {
      // case 'contact':
      //   openContactModal()
      //   break
      // case 'consent':
      //   openConsentModal()
      //   break
      default:
        pushModal(name)
    }
  }

  // let { openContactModal, ...contactProps } = useTolContact(
  //   pushModal,
  //   popModal,
  //   openModal,
  //   null,
  //   config,
  //   contactWithSaleContent
  // )

  // let { openConsentModal, ...consentProps } = useTolConsent(
  //   replaceModal,
  //   popModal,
  //   config
  // )

  let handleAction = action => {
    if (action === 'close-modal') {
      popModal()
    }
  }

  let { locale, defaultLocale } = useContext(I18nContext)

  packages = packages.map(tab => ({
    ...tab,
    // eslint-disable-next-line array-callback-return
    packages: tab.packages.map(pack => {
      if (pack.status) {
        if (pack['bundleStatus'] === true || pack['bundleStatus'] === null) {
          delete pack['bundleContent']
        } else {
          delete pack['image1']
          delete pack['image2']
          delete pack['image3']
        }

        return {
          ...pack,
          detailLink: {
            url: localizePath(
              '/trueonline' +
                `/package-types/${pack.packageOption.tolPackageType.slug}` +
                `/packages/${pack.slug}`,
              locale,
              defaultLocale
            ),
            newTab: false,
          },
        }
      }
    }),
  }))

  content = {
    ...content,
    packages: selectedPack
      ? [{ ...selectedPack, id: selectedPack.id.split(':').pop() }]
      : [],
  }

  // useCaptcha(config.captcha)

  return (
    <div {...props}>
      <Container>
        {renderContent(packages, setSelectedPack, openModal, preferWidth)}
      </Container>
      <DataLayerScope
        wrapContext={context => ({
          ...context,
          selectedPack,
          contactInputs: contactProps.inputs,
        })}
      >
        {/* <ContactModal
          content={content}
          show={modal === 'contact'}
          {...contactProps}
        />
        <ConsentModal
          content={content}
          show={modal === 'consent'}
          {...consentProps}
        /> */}
      </DataLayerScope>
      <TermsModal
        pack={selectedPack}
        show={modal === 'terms'}
        onAction={handleAction}
      />
    </div>
  )
}

export default InternetPackage
