const useInternetPackage = (type, data) => {
  if (type === 'all') {
    let { allTolPackages, viewAllManagement } = data
    allTolPackages = allTolPackages.filter(pack => {return pack.tolPackage != null}).map(pack => ({
      ...pack.tolPackage,
      id: `${pack.id}:${pack.tolPackage.id}`,
    }))

    return [
      {
        packages: allTolPackages,
        viewAll: viewAllManagement.tolPackageViewAll,
        selfServiceButton: viewAllManagement.tolPackageApply,
      },
    ]
  }

  if (type === 'highlight-package') {
    let { allTolPackages } = data

    allTolPackages = allTolPackages.map(pack => ({
      ...pack.tolPackage,
      id: `${pack.id}:${pack.tolPackage.id}`,
    }))

    return [{ packages: allTolPackages }]
  }
}

export default useInternetPackage
